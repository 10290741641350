import React, { useState, useEffect, useRef } from "react";
import InventoryTable from "../InventoryTable/InventoryTable";
import { get } from "../../api";
import "./App.css";
import logo from "../../logo.svg";

function App() {
  const [apiData, setData] = useState(null);
  // prevent dev reqest recall
  const renderAfterCalled = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      console.log("Fetching data...");
      try {
        const response = await get();

        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (!renderAfterCalled.current) {
      fetchData();
    }
    renderAfterCalled.current = true;
  }, []);

  return (
    <div className="App">
      <h1 className="App_title">
        {" "}
        <img alt="Cheeta logo" className="App_logo" src={logo} />
        Current Holds Inventory
      </h1>
      <div className="App_wrapper">
        {apiData ? (
          <InventoryTable
            colors={apiData.colors}
            inventory={apiData.inventory}
          />
        ) : (
          <h2>Loading data...</h2>
        )}
      </div>
    </div>
  );
}

export default App;
