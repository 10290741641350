import React from "react";
import InventoryRow from "./InventoryRow";
import { pickTextColorBasedOnBgColorAdvanced } from "../../utils/getColorContrast";
import "./InventoryTable.css";

function InventoryTable(props) {
  return (
    <table className="main_table">
      <thead>
        <tr>
          <th>Family</th>
          <th>Brand Reference</th>
          <th>Name</th>
          {props.colors.map((color) => (
            <th
              key={color.id}
              style={{
                backgroundColor: `#${color.color_hex}`,
                color: pickTextColorBasedOnBgColorAdvanced(
                  `#${color.color_hex}`,
                  "#fff",
                  "#000"
                ),
              }}
            >
              {color.color_name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.inventory.map((item, index) => (
          <InventoryRow
            key={item.id}
            previousFamily={props.inventory[index - 1]?.product_family}
            allColors={props.colors}
            product={item}
          />
        ))}
      </tbody>
    </table>
  );
}

export default InventoryTable;
