import React from "react";
import { pickTextColorBasedOnBgColorAdvanced } from "../../utils/getColorContrast";

function InventoryRow(props) {
  return (
    <tr key={props.product.id}>
      <td>
        {props.previousFamily &&
        props.previousFamily === props.product.product_family
          ? ""
          : props.product.product_family}
      </td>
      <td>{props.product.product_reference}</td>
      <td>{props.product.product_name}</td>
      {props.product.colors.map((colorId) => {
        const stockInfo = props.product.stock.find(
          (item) => item.color_id === colorId
        );
        const inventory = stockInfo ? stockInfo.stock : 0;
        const colorInfo = props.allColors.find(
          (color) => color.color_id === colorId
        );
        const cellColor = `#${colorInfo.color_hex}`;

        return (
          <td
            key={colorId}
            style={{
              backgroundColor: cellColor,
              color: pickTextColorBasedOnBgColorAdvanced(
                cellColor,
                "#fff",
                "#000"
              ),
            }}
          >
            {inventory === 0 ? "-" : inventory}
          </td>
        );
      })}
    </tr>
  );
}
export default InventoryRow;
