import axios from "axios";
// eslint-disable-next-line import/prefer-default-export
const api = axios.create({
  baseURL: "https://h1ben7le0e.execute-api.eu-central-1.amazonaws.com/api",
  headers: {
    "Content-Type": "application/json",
  },
  crossDomain: true,
});
export const get = () => api.get();
